let searchPage = document.querySelector('body.search');
let header = document.querySelector('header');
let search;
if(window.innerWidth < 1000) {
    search = document.querySelector('#main-menu form.search');
}
else {
    search = document.querySelector('header form.search');
}

if(header) {
    let searchBtn = header.querySelector('.search-btn:not(.close)');
    let closeBtn = header.querySelector('.search-btn.close');
    searchBtn.addEventListener('click', function(e) {
        if(!searchBtn.classList.contains('active')) {
            e.preventDefault();
            searchBtn.classList.add('active');
            search.closest('.search-wrapper').classList.add('active');
            setTimeout(function() {
                search.closest('.search-wrapper').classList.add('anim');
            }, 100);
            header.querySelector('#top-menu ul').classList.add('hide');
            search.querySelector('.search-input').focus();
        }
    });
    closeBtn.addEventListener('click', function(e) {
        e.preventDefault();
        closeSearch();
    });
    if (search) {
        let searchInput = search.querySelector('.search-input');
        searchInput.addEventListener('keyup', function (e) {
            if(e.target.value != '') {
                search.classList.add('active');
            }
            else {
                search.classList.remove('active');
            }
        });
        document.onkeydown = function(e) {
            if(e.key === "Escape" && search.closest('.search-wrapper').classList.contains('active')) {
                closeSearch();
            }
        }
    }
    function closeSearch() {
        searchBtn.classList.remove('active');
        search.closest('.search-wrapper').classList.remove('anim');
        setTimeout(function() {
            search.closest('.search-wrapper').classList.remove('active');
        }, 300);
        header.querySelector('#top-menu ul').classList.remove('hide');
        header.querySelector('.search-btn').focus();
    }
}


if (searchPage) {
    header.classList.add('dark');
    let pageSearchInput = searchPage.querySelector('#search-page-input');
    let inputValue = pageSearchInput.value;
    pageSearchInput.focus();
    pageSearchInput.value = '';
    pageSearchInput.value = inputValue;

    if(pageSearchInput.value.length > 0) {
        searchPage.querySelector('#search-page').classList.add('active');
    }

    pageSearchInput.addEventListener('keyup', function (e) {
        if(e.target.value != '') {
            searchPage.querySelector('#search-page').classList.add('active');
        }
        else {
            searchPage.querySelector('#search-page').classList.remove('active');
        }
    });
}
