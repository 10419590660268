const $block = $('.charger-product-comparison');

if($block) {
  const $filter = $block.find('.country-filter');
  if($filter) {
    $filter.find('a.current-item').click(function(e) {
      e.preventDefault();
  
      const $this = $(this);
      $this.parent().toggleClass('open');
    });

    $filter.find('.country-select li').click(function(e) {
      e.preventDefault();
      let country = $(this).find('a').attr('data-value');
      $filter.find('.country-select li').removeClass('active');
      $(this).addClass('active');
      if(country === 'all') {
        $filter.find('a.current-item span').text($(this).find('a').text());
        $block.find('.charger-product').show();
      }
      else {
        $filter.find('a.current-item span').text(country);
        $block.find('.charger-product').each(function(index, product) {
          let $product = $(product);
          $product.hide();
          let $countries = $product.attr('data-countries').split(",").map(c => c.trim());
          if($countries.includes(country)) {
            $product.show();
          }
        });
      }
      $filter.removeClass('open');
    });
  }
}