const $allLargeWordEls = $(".text-large-words .text");
const $backgroundColor = $(".text-large-words");
const $description = $(".text-large-words .title");
let hoverAllowed = true;
let $prevWord; 

const intersectionObserveWords = function(elements, rootMargin, onIntersectionChange) {
    if ('IntersectionObserver' in window) {
        const options = {
            root: null, // relative to document viewport
            rootMargin: rootMargin, // margin around root. Values are similar to css property. Unitless values not allowed
            threshold: 0 // visible amount of item shown in relation to root
        };

        const _onIntersectionChange = function (changes, observer) {
            changes.forEach(change => {
                if (onIntersectionChange) {
                    onIntersectionChange(change.target, change.intersectionRatio);
                }
            });
        };

        const observer = new IntersectionObserver(_onIntersectionChange, options);

        if (elements.forEach) {
            elements.forEach(element => observer.observe(element));
        } else {
            observer.observe(elements);
        }
    } else {
        if (elements.forEach) {
            elements.forEach(element => onIntersectionChange(element));
        } else {
            onIntersectionChange(elements);
        }
    }
};
if($allLargeWordEls.length > 0) {

    const allowWordHover = true;
    $allLargeWordEls.each((i, e) => {
        intersectionObserveWords(e, "10% 0px 10% 0px", (element, intersectionRatio) => {
            if(intersectionRatio > 0) {
                $(window).scroll((e) => {
                    hoverAllowed = false;
                    let windowHeight = window.innerHeight;
                    let children = $(element).children();
                    let $space = windowHeight/children.length;
                    let offsetTop = children.length < 6 ? element.getBoundingClientRect().top - windowHeight*0.2 : element.getBoundingClientRect().top;

                    for(let i = 0; i < children.length; i++) {
                        let $spaceOffset = i === 0 ? $space : $space * (i + 1);
                        let iPrev = i + 1

                        if(((offsetTop) <= (windowHeight - $spaceOffset)) && ((offsetTop) > (windowHeight - ($spaceOffset + $space)))) {
                            children[i].querySelector(".word").classList.add("focused");
                            if((offsetTop - windowHeight) && (children[iPrev]?.querySelector(".word").classList.contains("last"))) {
                                children[iPrev].querySelector(".word").classList.remove("focused");
                            }
                        } else {
                            if((!children[i].querySelector(".word").classList.contains("last"))) {
                                children[i].querySelector(".word").classList.remove("focused");
                            }
                        }
                    }
                });
            } else {
                //$(window).unbind("scroll")
            }
        });

        const $extraInfoWords = $(e).find(".extra-info-word");
        const $extraInfoContainer = $(e).find(".extra-info-container");
        const $currentLargeTextEl = $(e);
        if($extraInfoWords.length > 0) {
            const $childElements = $(e).children();
            const $outerExtraInfoContainers = $("section.text-large-words").find(".extra-info-outside");
            let $initialBgColor = $("section.text-large-words").data('bg-color');
            let $initialHeight = $("section.text-large-words").find('>div').height();
            let $tallestInfo = 0;
            $outerExtraInfoContainers.each(function(){
                var thisH = $(this).height();
                if (thisH > $tallestInfo) { 
                    $tallestInfo = thisH;
                }
             });
            
            $childElements.each(function(i,e) {
                $(e).find(".word").click(function() {
                    const $this = $(this);
                    if(window.innerWidth < 1000) {
                        const $classToRemoveFromDrawers = $this.parents("section.text-large-words").attr("class").split(" ")[1];
                        $this.parents("section.text-large-words").next().removeClass($classToRemoveFromDrawers);
                        $this.parents("section.text-large-words").prev().removeClass($classToRemoveFromDrawers);

                        $this.parents("div.text").find("p.word.focused").removeClass("focused");
                        $this.addClass("focused");
                        const $classToRemove = $this.parents("section.text-large-words").attr("class").split(" ")[1];
                        const $classToAdd = $this.attr("data-color");
                        $this.parents("section.text-large-words").removeClass($classToRemove);
                        $this.parents("section.text-large-words").addClass(`bg-${$classToAdd}`);
                        $this.parents("section.text-large-words").next().addClass(`bg-${$classToAdd}`);
                        $this.parents("section.text-large-words").prev().addClass(`bg-${$classToAdd}`);

                        if($this.prev().hasClass("shown")){
                            $this.prev().slideUp();
                            let $classToRemove = $this.parents("section.text-large-words").attr("class").split(" ")[1];
                            $this.parents("section.text-large-words").removeClass($classToRemove);
                            $this.parents("section.text-large-words").next().removeClass($classToRemove);
                            $this.parents("section.text-large-words").prev().removeClass($classToRemove);
                            $this.parents("section.text-large-words").addClass("bg-gray");
                            $this.prev().removeClass("shown");
                        } else {
                            $this.parents("section.text-large-words").find("div.shown").slideUp().removeClass("shown");
                            $this.prev().slideDown();
                            $this.prev().addClass("shown");
                        }
                    }
                });

                $(e).find(".word").mouseenter(function() {
                    /* if(hoverAllowed) { */
                        const $this = $(this).parent(); 
                        if(window.innerWidth > 1000 && allowWordHover === true) {
                            const offsetTop = this.offsetTop - 160;
                            const $curentWord = $this.attr("data-word");
                            const $outerExtraInfo = $this.parents("section.text-large-words").find(".extra-info-outside");
                            
                            if($(this).attr("data-index") === "4") {
                                $outerExtraInfo.addClass("dark");
                            } else {
                                $outerExtraInfo.removeClass("dark");
                            }

                            if($outerExtraInfo.hasClass("showed")) {
                                $outerExtraInfo.addClass("transitions-enabled");
                            } else {
                                $outerExtraInfo.removeClass("transitions-enabled");
                            }
                            $outerExtraInfo.css("top", offsetTop)

                            $extraInfoWords.each(function(i,e) {
                                const $wordInfo = $(this).attr("data-word");
                                if($wordInfo === $curentWord) {
                                    $outerExtraInfo.html($this.find("div").html());
                                    $outerExtraInfo.parent('.title').parent('div').css('height', $tallestInfo);
                                }
                            })

                            $("p.word.focused").removeClass("focused");
                            $this.find("p.word").addClass("focused");
                            $this.parents("section.text-large-words").find(".extra-info-outside").addClass("show");
                            $this.parents("section.text-large-words").find(".extra-info-outside").addClass("showed");
                
                            const $classToRemove = $this.parents("section.text-large-words").attr("class").split(" ")[1];
                            const $classToAdd = $this.find("p.word").attr("data-color");
                            $this.parents("section.text-large-words").removeClass($classToRemove);
                            $this.parents("section.text-large-words").addClass(`bg-${$classToAdd}`);
                            $this.parents("section.text-large-words").find("div.title .title-wrapper").removeClass("show");
                            $this.parents("section.text-large-words").next().addClass(`bg-${$classToAdd}`);
                            $this.parents("section.text-large-words").prev().addClass(`bg-${$classToAdd}`);

                            $prevWord = $this.attr("data-word");
                        }
                    /* } */
                });
                $(e).find(".word").mouseleave(function() {
                    const $this = $(this).parent(); 
                    if(window.innerWidth > 1000 && allowWordHover === true) {
                        const $outerExtraInfo = $this.parents("section.text-large-words").find(".extra-info-outside");
                        $outerExtraInfo.parent('.title').parent('div').css('height', $initialHeight);
                        $this.find("p.word").removeClass("focused");
                        $this.parents("section.text-large-words").find(".extra-info-outside").removeClass("show");
                        const $classToRemove = $backgroundColor.attr("class").split(" ")[1];
                        $this.parents("section.text-large-words").removeClass($classToRemove);
                        $this.parents("section.text-large-words").addClass(`bg-${$initialBgColor}`);
                        $this.parents("section.text-large-words").next().removeClass($classToRemove);
                        $this.parents("section.text-large-words").prev().removeClass($classToRemove);
                        $this.parents("section.text-large-words").find("div.title .title-wrapper").addClass("show");

                        setTimeout(function() {
                            if(!$this.parents("section.text-large-words").find(".extra-info-outside").hasClass("show")) {
                                $this.parents("section.text-large-words").find(".extra-info-outside").removeClass("showed");
                            }
                        }, 300);
                    }
                });
            });

            /* jQuery('img.svg').each(function(){
                var $img = jQuery(this);
                var imgClass = $img.attr('class');
                var imgURL = $img.attr('src');

                jQuery.get(imgURL, function(data) {
                    // Get the SVG tag, ignore the rest
                    var $svg = jQuery(data).find('svg');

                    // Add replaced image's classes to the new SVG
                    if(typeof imgClass !== 'undefined') {
                        $svg = $svg.attr('class', imgClass+' replaced-svg');
                    }

                    // Remove any invalid XML tags as per http://validator.w3.org
                    $svg = $svg.removeAttr('xmlns:a');

                    // Replace image with new SVG
                    $img.replaceWith($svg);

                }, 'xml');

            }); */

            $(window).resize(function(e){
                if(window.innerWidth > 1000) {    
                    $backgroundColor.each(function() {
                        const $element = $(this);
                        if(($element.attr("data-animations") === "true") && ($currentLargeTextEl.find("div.extra-info-container.shown").length > 0)) {
                            const $classToRemoveFromDrawers = $currentLargeTextEl.parents("section.text-large-words").next().attr("class").split(" ")[1];
                            $currentLargeTextEl.parents("section.text-large-words").next().removeClass($classToRemoveFromDrawers);
                            $currentLargeTextEl.parents("section.text-large-words").prev().removeClass($classToRemoveFromDrawers);
                            $currentLargeTextEl.parents("section.text-large-words").removeClass($classToRemoveFromDrawers);    
                            $currentLargeTextEl.parents("section.text-large-words").addClass("bg-gray"); 
                            $currentLargeTextEl.find("div.extra-info-container.shown").removeClass("shown");
                            $currentLargeTextEl.find("div.extra-info-container").hide();
                        }
                    })
                }
            })
        }
    }) 
}
